<template>
    <div v-if="loading" class="loading-container">
      <LoadingOutlined />
    </div>
    <div v-else>
      <div class="container-table">
        <div class="item-header">
          <img src="../../assets/icons/flag_icon.svg" alt="flag_icon" />
          <h5>{{ t("athletes.nation_history") }}</h5>
        </div>
        <div :style="{ overflowY: 'auto' }">
          <a-table
            class="team-table"
            size="middle"
            :columns="onSetColumns('history')"
            :data-source="teamHistory.data"
            :pagination="false"
            :bordered="false"
            rowKey="history"
          />
        </div>

        <div class="pagination-container">
          <p>
            {{
              `1-${teamHistory?.perPage} ${t("label.table.of")} ${
                teamHistory?.total
              } ${t("label.table.items")}`
            }}
          </p>
          <a-pagination
            :current="currentPageNation"
            :page-size-options="pageSizeOptionsNation"
            :total="teamHistory?.total"
            show-size-changer
            :page-size="teamHistory.perPage"
            @change="onPageChangeNation"
            @showSizeChange="onShowSizeChangeNation"
          >
            <template #buildOptionText="props">
              <span v-if="props.value !== teamHistory?.perPage">{{
                props.value
              }}</span>
              <span v-else>ทั้งหมด</span>
            </template>
          </a-pagination>
        </div>
      </div>
      <div class="container-table">
        <div class="item-header">
          <img src="../../assets/icons/shield_icon.svg" alt="shield_icon" />
          <h5>{{ t("athletes.club_honor") }}</h5>
        </div>
        <div :style="{ overflowY: 'auto' }">
          <a-table
            class="team-table"
            size="middle"
            :columns="onSetColumns('club')"
            :data-source="teamHistoryClub.data"
            :pagination="false"
            :bordered="false"
            rowKey="club"
          />
        </div>

        <div class="pagination-container">
          <p>
            {{
              `1-${teamHistoryClub?.perPage} ${t("label.table.of")} ${
                teamHistoryClub?.total
              } ${t("label.table.items")}`
            }}
          </p>
          <a-pagination
            :current="currentPageClub"
            :page-size-options="pageSizeOptionsClub"
            :total="teamHistoryClub?.total"
            show-size-changer
            :page-size="teamHistoryClub.perPage"
            @change="onPageChangeClub"
            @showSizeChange="onShowSizeChangeClub"
          >
            <template #buildOptionText="props">
              <span v-if="props.value !== teamHistoryClub?.perPage">{{
                props.value
              }}</span>
              <span v-else>ทั้งหมด</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { onMounted, reactive, toRefs, watch } from "vue";
  import { useRequest } from "vue-request";
  import { athleteService } from "@/_services";
  import { LoadingOutlined } from "@ant-design/icons-vue";
  import ShieldGoldIcon from "../../assets/logo-placeholder-brown.svg";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex";
  export default {
    name: "Tab2",
    components: {
      LoadingOutlined,
      ShieldGoldIcon,
    },
    props: {
      athleteId: Number,
    },
    setup(props) {
      const { athleteId } = props;
      const { t } = useI18n({
        useScope: "global",
      });

      const store = useStore();
      const state = reactive({
        columns: {
          th: [
            {
              key: "icon",
              title: "",
              dataIndex: "setting_teams",
              align: "center",
              customRender: ({ text, record, index }) => {
                return (
                  <div>
                    {text?.setting_team_medias?.setting_team_media_icon ? (
                      <img
                        src={text?.setting_team_medias?.setting_team_media_icon}
                        alt="team-logo"
                        style={{ width: "30px" }}
                      />
                    ) : (
                      <img
                        src={ShieldGoldIcon}
                        alt="team-logo"
                        style={{ width: "30px" }}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              key: "position",
              // title: "ตำแหน่ง",

              title: "ตำแหน่ง",
              dataIndex: "position",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record.global_config_position_name_th}</p>;
              },
              width: "150px",
            },
            {
              key: "shirt_number",
              title: "หมายเลขเสื้อ",
              dataIndex: "shirt_number",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record?.team_player_history_shirt_number || "-"}</p>;
              },
            },
            {
              key: "match",
              title: "จำนวนนัดที่ลงเล่น",
              dataIndex: "match",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record?.team_player_history_match_count || "-"}</p>;
              },
            },
            {
              key: "contract",
              title: "รูปแบบสัญญา",
              dataIndex: "contract",
              align: "center",
              customRender: ({ text, record, index }) => {
                return (
                  <p>{record?.team_player_history_loan ? "ยืมตัว" : "ประจำ"}</p>
                );
              },
            },
            {
              key: "start_date",
              title: "วันที่เริ่มต้น",
              dataIndex: "start_date",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record?.team_player_history_begin_at || "-"}</p>;
              },
            },
            {
              key: "end_date",
              title: "วันที่สิ้นสุด",
              dataIndex: "end_date",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record?.team_player_history_end_at || "-"}</p>;
              },
            },
          ],
          en: [
            {
              key: "icon",
              title: "",
              dataIndex: "setting_teams",
              align: "center",
              customRender: ({ text, record, index }) => {
                return (
                  <div>
                    {text?.setting_team_medias?.setting_team_media_icon ? (
                      <img
                        src={text?.setting_team_medias?.setting_team_media_icon}
                        alt="team-logo"
                        style={{ width: "30px" }}
                      />
                    ) : (
                      <img
                        src={ShieldGoldIcon}
                        alt="team-logo"
                        style={{ width: "30px" }}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              key: "position",
              // title: "ตำแหน่ง",

              title: "Position",
              dataIndex: "position",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record.global_config_position_name_en}</p>;
              },
              width: "150px",
            },
            {
              key: "shirt_number",
              title: "Shirt Number",
              dataIndex: "shirt_number",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record?.team_player_history_shirt_number || "-"}</p>;
              },
            },
            {
              key: "match",
              title: "Matches Played",
              dataIndex: "match",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record?.team_player_history_match_count || "-"}</p>;
              },
            },
            {
              key: "contract",
              title: "Type of Contract",
              dataIndex: "contract",
              align: "center",
              customRender: ({ text, record, index }) => {
                const loan =
                  store.state.currentLanguage === "th" ? "ยืม" : "Loan";
                const permanent =
                  store.state.currentLanguage === "th" ? "ประจำ" : "Permanent";
                return (
                  <p>{record?.team_player_history_loan ? loan : permanent}</p>
                );
              },
            },
            {
              key: "start_date",
              title: "Start Date",
              dataIndex: "start_date",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record?.team_player_history_begin_at || "-"}</p>;
              },
            },
            {
              key: "end_date",
              title: "End Date",
              dataIndex: "end_date",
              align: "center",
              customRender: ({ text, record, index }) => {
                return <p>{record?.team_player_history_end_at || "-"}</p>;
              },
            },
          ],
        },
        loading: true,
        teamHistory: {},
        teamHistoryClub: {},
        pageSizeOptionsNation: ["10", "20", "50", "100"],
        pageSizeOptionsClub: ["10", "20", "50", "100"],
        currentPageNation: 1,
        currentPageClub: 1,
      });

      const { run: fetchAthleteTeamHistoryNation } = useRequest(
        athleteService.getTeamHistoryNation,
        {
          manual: true,
          onSuccess: (formattedResult) => {
            state.teamHistory = formattedResult.data;
          },
        }
      );

      const { run: fetchAthleteTeamHistoryClub } = useRequest(
        athleteService.getTeamHistoryClub,
        {
          manual: true,
          onSuccess: (formattedResult) => {
            state.teamHistoryClub = formattedResult.data;
          },
        }
      );

      const onSetColumns = (type) => {
        if (store.state.currentLanguage === "th") {
          const col1 = [...state.columns.th];
          const col2 = [...state.columns.th];
          if (type === "history") {
            col1.splice(1, 0, {
              key: "name",
              title: "ทีมชาติชุด",
              dataIndex: "setting_teams",
              customRender: ({ text, record, index }) => {
                return (
                  <p>
                    {record?.setting_teams?.setting_team_informations
                      ?.setting_team_information_name_th || "-"}
                  </p>
                );
              },
              width: "200px",
            });
            return col1;
          } else {
            col2.splice(1, 0, {
              key: "name",
              title: "ชื่อทีม/สโมสร",
              dataIndex: "setting_teams",
              customRender: ({ text, record, index }) => {
                return (
                  <p>
                    {record?.setting_teams?.setting_team_informations
                      ?.setting_team_information_name_th || "-"}
                  </p>
                );
              },
              width: "200px",
            });
            return col2;
          }
        } else {
          const col1 = [...state.columns.en];
          const col2 = [...state.columns.en];
          if (type === "history") {
            col1.splice(1, 0, {
              key: "name",
              title: "Team Name",
              dataIndex: "setting_teams",
              customRender: ({ text, record, index }) => {
                return (
                  <p>
                    {record?.setting_teams?.setting_team_informations
                      ?.setting_team_information_name_en || "-"}
                  </p>
                );
              },
              width: "200px",
            });
            return col1;
          } else {
            col2.splice(1, 0, {
              key: "name",
              title: "Team/Club",
              dataIndex: "setting_teams",
              customRender: ({ text, record, index }) => {
                return (
                  <p>
                    {record?.setting_teams?.setting_team_informations
                      ?.setting_team_information_name_en || "-"}
                  </p>
                );
              },
              width: "200px",
            });
            return col2;
          }
        }
      };

      onMounted(async () => {
        try {
          await onFetchNation();
          await onFetchClub();
        } catch (error) {
          // console.log("tab2 fetch :: error :: ", error);
        } finally {
          state.loading = false;
        }
      });

      const onFetchNation = async (params) => {
        await fetchAthleteTeamHistoryNation(athleteId, params);
      };

      const onFetchClub = async (params) => {
        await fetchAthleteTeamHistoryClub(athleteId, params);
      };

      const onPageChangeNation = (page, pageSize) => {
        state.currentPageNation = page;
        onFetchNation({ page, size: pageSize });
      };

      const onShowSizeChangeNation = (current, pageSize) => {
        state.currentPageNation = current;
        onFetchNation({ page: current, size: pageSize });
      };

      const onPageChangeClub = (page, pageSize) => {
        state.currentPageClub = page;
        onFetchClub({ page, size: pageSize });
      };

      const onShowSizeChangeClub = (current, pageSize) => {
        state.currentPageClub = current;
        onFetchClub({ page: current, size: pageSize });
      };
      watch(
        () => store.state.currentLanguage,
        (newValue) => {
          onSetColumns("club");
          onSetColumns("history");
        }
      );
      return {
        ...toRefs(state),
        t,
        store,
        onSetColumns,
        onShowSizeChangeNation,
        onPageChangeNation,
        onPageChangeClub,
        onShowSizeChangeClub,
      };
    },
  };
  </script>

  <style lang="less" scoped>
  .item-header {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    h5 {
      margin: auto 5px;
    }
    img {
      width: 25px;
    }
  }
  .loading-container {
    text-align: center;
  }
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    p {
      margin: auto 0px;
    }
  }

  // :deep(.sat-table-thead > tr:first-child > th:first-child) {
  //   border-top-left-radius: 10px;
  //   border-bottom-left-radius: 10px;
  // }
  // :deep(.sat-table-thead > tr:first-child > th:last-child) {
  //   border-top-right-radius: 10px;
  //   border-bottom-right-radius: 10px;
  // }
  // :deep(.sat-pagination-item-link) {
  //   border-radius: 30px;
  //   // background-color: #F8F8F8;
  // }
  // :deep(.sat-pagination-item-active) {
  //   border-radius: 30px;
  //   background-color: #0f4c82;
  //   color: white;
  //   &:focus {
  //     color: white;
  //   }
  // }
  // :deep(.sat-pagination-item) {
  //   border-radius: 30px;
  // }
  </style>