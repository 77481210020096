<template>
    <div v-if="loading" class="loading-container">
      <LoadingOutlined />
    </div>
    <div v-else>
      <div class="container-table">
        <div class="item-header">
          <!-- <img src="../../assets/icons/cub_icon.svg" alt="flag_icon" /> -->
          <h5>{{ t("athletes.penalty") }}</h5>
        </div>
        <div :style="{ overflowY: 'auto' }">
          <a-table
            class="team-table"
            size="middle"
            :columns="columns"
            :data-source="penaltiesCurrent.data"
            :pagination="false"
            :bordered="false"
            :rowKey="onRowKey"
          />
        </div>

        <div class="pagination-container">
          <p>
            {{
              `1-${penaltiesCurrent?.itemsPerPage} ${t("label.table.of")} ${
                penaltiesCurrent?.totalItems
              } ${t("label.table.items")}`
            }}
          </p>
          <a-pagination
            :current="currentPagePenaltiesCurrent"
            :page-size-options="pageSizeOptions"
            :total="penaltiesCurrent?.totalItems"
            show-size-changer
            :page-size="penaltiesCurrent.itemsPerPage"
            @change="onPageChangePenaltiesCurrent"
            @showSizeChange="onShowSizeChangePenaltiesCurrent"
          >
            <template #buildOptionText="props">
              <span v-if="props.value !== penaltiesCurrent?.itemsPerPage">{{
                props.value
              }}</span>
              <span v-else>ทั้งหมด</span>
            </template>
          </a-pagination>
        </div>
      </div>
      <div class="container-table">
        <div class="item-header">
          <!-- <img src="../../assets/icons/cub_icon.svg" alt="flag_icon" /> -->
          <h5>{{ t("athletes.penalty_history") }}</h5>
        </div>
        <div :style="{ overflowY: 'auto' }">
          <a-table
            class="team-table"
            size="middle"
            :columns="columns"
            :data-source="penalties.data"
            :pagination="false"
            :bordered="false"
            :rowKey="onRowKey"
          />
        </div>

        <div class="pagination-container">
          <p>
            {{
              `1-${penalties?.itemsPerPage} ${t("label.table.of")} ${
                penalties?.totalItems
              } ${t("label.table.items")}`
            }}
          </p>
          <a-pagination
            :current="currentPagePenalties"
            :page-size-options="pageSizeOptions"
            :total="penalties?.totalItems"
            show-size-changer
            :page-size="penalties.itemsPerPage"
            @change="onPageChangePenalties"
            @showSizeChange="onShowSizeChangePenalties"
          >
            <template #buildOptionText="props">
              <span v-if="props.value !== penalties?.itemsPerPage">{{
                props.value
              }}</span>
              <span v-else>ทั้งหมด</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { onMounted, reactive, toRefs, watch, watchEffect } from "vue";
  import { LoadingOutlined } from "@ant-design/icons-vue";
  import { useRequest } from "vue-request";
  import { athleteService } from "@/_services";
  import { Helpers } from "@/_modules/helpers";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex";
  export default {
    components: {
      LoadingOutlined,
    },
    props: {
      athleteId: Number,
    },
    setup(props) {
      const { athleteId } = props;
      const { t } = useI18n({
        useScope: "global",
      });
      const store = useStore();
      const { run: fetchPenaltiesCurrent } = useRequest(
        athleteService.getPenaltiesCurrent,
        {
          manual: true,
          onSuccess: (formattedResult) => {
            state.penaltiesCurrent = formattedResult.data;
          },
        }
      );
      const { run: fetchPenalties } = useRequest(athleteService.getPenalties, {
        manual: true,
        onSuccess: (formattedResult) => {
          state.penalties = formattedResult.data;
        },
      });

      onMounted(async () => {
        try {
          await onFetchPenaltiesCurrent();
          await onFetchPenalties();
        } catch (error) {
          // console.log("Tab5 fetch :: error ", error);
        } finally {
          state.loading = false;
        }
      });
      const onFetchPenaltiesCurrent = async (params) => {
        await fetchPenaltiesCurrent(athleteId, params);
      };
      const onFetchPenalties = async (params) => {
        await fetchPenalties(athleteId, params);
      };
      const state = reactive({
        penaltiesCurrent: {},
        penalties: {},
        loading: true,
        columns: [
          {
            key: "account_penalty_title_th",
            title: "ชื่อบทลงโทษ",
            dataIndex: "account_penalty_title_th",
            // align: "center",
            width: "250px",
          },
          {
            key: "account_penalty_competition_name",
            title: "รายการแข่งขัน",
            dataIndex: "account_penalty_competition_name",
            // align: "center",
            width: "200px",
          },
          {
            key: "account_penalty_competition_match",
            title: "รอบการแข่งขัน",
            dataIndex: "account_penalty_competition_match",
            // align: "center",
            width: "150px",
          },
          {
            key: "account_penalty_date",
            title: "วันที่โดนบทลงโทษ",
            dataIndex: "account_penalty_date",
            align: "center",
            width: "150px",
          },
          {
            key: "account_penalty_duration",
            title: "ระยะเวลาที่โดนลงโทษ",
            dataIndex: "account_penalty_duration",
            align: "center",
            customRender: ({ text, record, index }) => {
              if (store.state.currentLanguage === "th") {
                return `${record.account_penalty_duration} ${
                  Helpers.EDurationUnit(record.account_penalty_duration_unit).th
                }`;
              }
              return `${record.account_penalty_duration} ${
                Helpers.EDurationUnit(record.account_penalty_duration_unit).en
              }`;
            },
            width: "180px",
          },
          {
            key: "account_penalty_appeal_conclusion",
            title: "การยื่นอุทธรณ์",
            dataIndex: "account_penalty_appeal_conclusion",
            align: "center",
            customRender: ({ text, record, index }) => {
              if (store.state.currentLanguage === "th") {
                return text ? "มีการยื่นอุทธรณ์" : "ไม่มีการยื่นอุทธรณ์";
              }
              return text ? "Appealing" : "No Appealing";
            },
          },
        ],
        currentPagePenaltiesCurrent: 1,
        currentPagePenalties: 1,
        pageSizeOptions: ["10", "20", "50", "100"],
      });

      const onPageChangePenaltiesCurrent = (page, pageSize) => {
        state.currentPagePenaltiesCurrent = page;
        onFetchPenaltiesCurrent({ page, size: pageSize });
      };

      const onShowSizeChangePenaltiesCurrent = (current, pageSize) => {
        state.currentPagePenaltiesCurrent = current;
        onFetchPenaltiesCurrent({ page: current, size: pageSize });
      };

      const onPageChangePenalties = (page, pageSize) => {
        state.currentPagePenaltiesCurrent = page;
        onFetchPenalties({ page, size: pageSize });
      };

      const onShowSizeChangePenalties = (current, pageSize) => {
        state.currentPagePenaltiesCurrent = current;
        onFetchPenalties({ page: current, size: pageSize });
      };

      const onRowKey = (record) => record.id;

      watch(
        () => store.state.currentLanguage,
        (newValue) => {
          if (newValue === "th") {
            state.columns[0].title = "ชื่อบทลงโทษ";
            state.columns[0].dataIndex = "account_penalty_title_th";

            state.columns[1].title = "รายการแข่งขัน";
            state.columns[1].dataIndex = "account_penalty_competition_name";

            state.columns[2].title = "รอบการแข่งขัน";
            state.columns[2].dataIndex = "account_penalty_competition_match";

            state.columns[3].title = "วันที่โดนบทลงโทษ";

            state.columns[4].title = "ระยะเวลาที่โดนลงโทษ";
            state.columns[4].dataIndex = "account_penalty_duration";

            state.columns[5].title = "การยื่นอุทธรณ์";
          } else {
            state.columns[0].title = "Penalty Name";
            state.columns[0].dataIndex = "account_penalty_title_en";

            state.columns[1].title = "Competition Name";
            state.columns[1].dataIndex = "account_penalty_competition_name_en";

            state.columns[2].title = "Match";
            state.columns[2].dataIndex = "account_penalty_competition_match_en";

            state.columns[3].title = "Date of Penalty";

            state.columns[4].title = "Punishment Duration";

            state.columns[5].title = "Appealing";
          }
        }
      );



      return {
        t,
        store,
        ...toRefs(state),
        Helpers,
        onPageChangePenaltiesCurrent,
        onShowSizeChangePenaltiesCurrent,
        onPageChangePenalties,
        onShowSizeChangePenalties,
        onRowKey,
      };
    },
  };
  </script>

  <style lang="less" scoped>
  p {
    margin: auto 0px;
  }
  .item-header {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    h5 {
      margin: auto 5px;
    }
    .text-white {
      color: white;
    }
    img {
      width: 25px;
    }
  }
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    p {
      margin: auto 0px;
    }
  }
  .loading-container {
    text-align: center;
  }
  </style>